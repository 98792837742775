<template>
    <div class="app-container">
        <div class="filter-container">
            <el-radio-group v-model="listQuery.type" class="filter-item" size="small" @change="handleFilter">
                <el-radio-button :label="1">外卖</el-radio-button>
                <el-radio-button :label="2">商城</el-radio-button>
            </el-radio-group>
        </div>

        <div class="filter-container">
            <el-button class="filter-item" type="primary" size="small" @click="handleCreate">新增</el-button>

            <el-select v-model="listQuery.shop_id" placeholder="请选择店铺" style="width: 200px" class="filter-item" size="small" filterable clearable>
                <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id">
                </el-option>
            </el-select>

            <el-date-picker v-model="listQuery.effective_date" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="请选择生效日期" style="width: 200px;" class="filter-item" size="small" clearable></el-date-picker>

            <el-button class="filter-item" type="primary" size="small" @click="handleFilter">搜索</el-button>
        </div>

        <div class="filter-container">
            <el-button-group>
                <el-button type="danger" size="small" @click="handleDel(null)" :disabled="ids.length <= 0">批量删除</el-button>
            </el-button-group>
        </div>

        <el-table v-loading="listLoading" :data="list" @selection-change="handleSelectionChange" element-loading-text="Loading" fit highlight-current-row>
            <el-table-column type="selection" align="center" width="60"></el-table-column>

            <el-table-column align="center" label="ID" width="80">
                <template slot-scope="scope">
                    {{ scope.row.id }}
                </template>
            </el-table-column>

            <el-table-column label="学校" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.school?scope.row.school.school_name:'' }}
                </template>
            </el-table-column>

            <el-table-column label="类型" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.type === 1" type="primary">外卖</el-tag>
                    <el-tag v-else-if="scope.row.type === 2" type="warning">商城</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="店铺" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.shop.shop_name }}
                </template>
            </el-table-column>

            <el-table-column label="生效日期" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.effective_date }}
                </template>
            </el-table-column>

            <el-table-column label="开始时间" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.start_time }}
                </template>
            </el-table-column>

            <el-table-column label="结束时间" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.end_time }}
                </template>
            </el-table-column>

            <el-table-column label="操作" fixed="right" align="center" width="200" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button type="danger" size="mini" @click="handleDel(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <div class="pagination-container">
            <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>

        <!-- 详情 -->
        <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" width="50%" :close-on-click-modal="false">
            <el-form ref="form" :rules="rules" :model="form" label-position="left" label-width="150px">
                <el-form-item label="类型" prop="type">
                    <el-radio-group v-model="form.type" disabled>
                        <el-radio-button :label="1">外卖</el-radio-button>
                        <el-radio-button :label="2">商城</el-radio-button>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="店铺" prop="shop_ids">
                    <el-select v-model="form.shop_ids" filterable multiple collapse-tags placeholder="请选择店铺" clearable>
                        <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="生效时间" prop="effective_time">
                    <el-table :data="form.effective_time" border fit highlight-current-row style="margin-top: 15px">
                        <el-table-column label="生效日期" min-width="110" align="center">
                            <template slot-scope="scope">
                                <el-date-picker size="small" v-model="scope.row.effective_date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" style="width: 100%;max-width: 150px;"></el-date-picker>
                            </template>
                        </el-table-column>

                        <el-table-column label="开始时间" min-width="110" align="center">
                            <template slot-scope="scope">
                                <el-time-picker size="small" v-model="scope.row.start_time" format="HH:mm" value-format="HH:mm" placeholder="选择时间" style="width: 100%;max-width: 150px;"></el-time-picker>
                            </template>
                        </el-table-column>

                        <el-table-column label="结束时间" min-width="110" align="center">
                            <template slot-scope="scope">
                                <el-time-picker size="small" v-model="scope.row.end_time" format="HH:mm" value-format="HH:mm" placeholder="选择时间" style="width: 100%;max-width: 150px;"></el-time-picker>
                            </template>
                        </el-table-column>

                        <el-table-column label="操作" min-width="110" align="center">
                            <template slot-scope="scope">
                                <el-button size="small" type="primary" @click="addEffectiveTime(scope.$index, scope.row)">添加</el-button>

                                <el-button size="small" type="danger" @click="delEffectiveTime(scope.$index)" v-if="form.effective_time.length > 1">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
            </div>
        </el-dialog>
        <!-- 详情 -->
    </div>
</template>

<script>
    import request from "@/utils/request";
    import { mapGetters } from "vuex";

    export default {
        data() {
            return {
                list: null,
                total: null,
                listLoading: true,
                listQuery: {
                    page: 1,
                    limit: 10,
                    school_id: '',
                    type: 1,
                    shop_id: '',
                    effective_date: '',
                },
                dialogFormVisible: false,
                dialogStatus: '',
                textMap: {
                    update: '修改',
                    create: '新增',
                },
                btnLoading: false,
                form:{
                    id: '',
                    school_id: '',
                    type: '',
                    shop_ids: '',
                    effective_time: [
                        {
                            effective_date: '',
                            start_time: '',
                            end_time: '',
                        }
                    ],
                },
                rules: {
                    type: [{ required: true, message: '请选择类型', trigger: 'change' }],
                    shop_ids: [{ required: true, message: '请选择店铺', trigger: 'change' }],
                },
                shops: [],
                ids: [],
            };
        },
        created() {
            this.getList();
            this.getShopList();
        },
        computed: {
            ...mapGetters(["schools", "school_id","user"]),
        },
        watch: {
            "listQuery.type": {
                handler(newValue, oldValue) {
                    this.getShopList();
                },
                deep: true
            },
            school_id(newVal, oldVal) {
                this.listQuery.school_id = newVal;
                this.getList();
                this.getShopList();
            },
        },
        methods: {
            getList() {
                this.listQuery.school_id = this.school_id;
                this.listLoading = true;
                request({
                    url: "/api/backend/recommendShop/list",
                    method: "get",
                    params: this.listQuery
                }).then(response => {
                    this.list = response.data.data;
                    this.total = response.data.total;
                    this.listLoading = false;
                });
            },
            getShopList() {
                request({
                    url: "/api/backend/shop/list",
                    method: "get",
                    params: {
                        page: 1,
                        limit: 1000000,
                        school_id: this.school_id,
                        shop_type: this.listQuery.type,
                    }
                }).then(response => {
                    this.shops = response.data.data;
                });
            },
            handleFilter() {
                this.listQuery.page = 1;
                this.getList();
            },
            handleSizeChange(val) {
                this.listQuery.limit = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.listQuery.page = val;
                this.getList();
            },
            resetForm() {
                this.form = {
                    id: '',
                    school_id: '',
                    type: this.listQuery.type || '',
                    shop_ids: '',
                    effective_time: [
                        {
                            effective_date: '',
                            start_time: '',
                            end_time: '',
                        }
                    ],
                }
                this.btnLoading = false
            },
            handleCreate() {
                this.resetForm()
                this.dialogStatus = 'create'
                this.dialogFormVisible = true
                this.$nextTick(() => {
                    this.$refs['form'].clearValidate() //清除校验结果
                })
            },
            saveData() {
                if (!this.school_id) {
                    this.$message({
                        type: "warning",
                        message: "请选择学校"
                    });
                    return;
                } else {
                    this.form.school_id = this.school_id;
                }

                if (this.form.effective_time.length === 0) {
                    this.$message({
                        type: "warning",
                        message: "生效时间不能为空",
                    });
                    return;
                }
                for (let index = 0; index < this.form.effective_time.length; index++) {
                    const element = this.form.effective_time[index];

                    if (element.effective_date === '') {
                        this.$message({
                            type: "warning",
                            message: "生效日期不能为空"
                        });
                        return;
                    }

                    if (element.start_time === '') {
                        this.$message({
                            type: "warning",
                            message: "开始时间不能为空"
                        });
                        return;
                    }

                    if (element.end_time === '') {
                        this.$message({
                            type: "warning",
                            message: "结束时间不能为空"
                        });
                        return;
                    }

                    if (element.start_time >= element.end_time) {
                        this.$message({
                            type: "warning",
                            message: "结束时间不能低于或等于开始时间"
                        });
                        return;
                    }
                }

                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.btnLoading = true
                        request({
                            url: '/api/backend/recommendShop/save',
                            method: 'post',
                            data: this.form
                        }).then(() => {
                            this.btnLoading = false
                            this.dialogFormVisible = false
                            this.$message({
                                type: 'success',
                                message: '操作成功'
                            });
                            this.getList();
                        }).catch(() => {
                            this.btnLoading = false
                        })
                    }
                })
            },
            handleSelectionChange(val) {
                let ids = [];
                val.forEach(element => {
                    ids.push(element.id);
                });
                this.ids = ids;
            },
            handleDel(item) {
                this.$confirm('确定要删除吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    request({
                        url: '/api/backend/recommendShop/del',
                        method: 'post',
                        data: {
                            ids: item ? [item.id] : this.ids
                        }
                    }).then(() => {
                        // 删除最后一条数据时无数据问题
                        this.list.length <= 1 ? this.listQuery.page-- : false;
                        this.getList();
                        this.$message({
                            type: 'success',
                            message: '操作成功'
                        });
                    });
                }).catch(() => {

                });
            },
            // 添加生效时间
            addEffectiveTime(index, row) {
                let obj = {
                    effective_date: row.effective_date,
                    start_time: row.start_time,
                    end_time: row.end_time,
                };

                this.form.effective_time.splice(index+1, 0, obj)
            },
            // 删除生效时间
            delEffectiveTime(index) {
                this.form.effective_time.splice(index, 1)
            },
        }
    };
</script>


<style rel="stylesheet/scss" lang="scss" scoped>
    .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 6px;
    }
</style>
